import { SvgIcon } from '@mui/material';
import { GetServerSidePropsContext } from 'next';
import { useTranslations } from 'next-intl';
import { useRouter } from 'next/router';
import { useSnackbar } from 'notistack';
import qs from 'qs';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Footer from '../components/footer/footer';
import Logo from '../public/images/ChristaudLogo.svg';
import { resolvedTokenAction } from '../redux/actions/userActionCreator';
import { GlobalState } from '../redux/reducers';
import { TokenData } from '../redux/reducers/userReducer';
import { getTokenData } from '../redux/selectors/user';
import Security from '../services/security';
import { LOGIN_PAGE } from '../services/utils/CONST';
import { getLocale } from '../services/utils/get-translates';
import { routeWithParamAs } from '../services/utils/route';

const CORE_API = process.env.NEXT_PUBLIC_CORE_API;

interface Props {
    token: string;
    mode: string;
}

const Index = ({ token, mode }: Props) => {
    const router = useRouter();
    const tCommon = useTranslations('common');
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const tokenData: TokenData | null = useSelector((state: GlobalState) => getTokenData(state));

    useEffect(() => {
        if (token) {
            dispatch(resolvedTokenAction.fn(token));
        } else {
            enqueueSnackbar('noToken', {
                variant: 'error',
            });
        }
    }, []);

    useEffect(() => {
        if (tokenData) {
            setTimeout(() => {
                routeWithParamAs(router, `${mode ? mode : LOGIN_PAGE}?${qs.stringify(router.query)}`);
            }, 1000);
        }
    }, [tokenData]);

    return (
        <Fragment>
            <div className='custom-div'>
                <div className='container'>
                    <div className='title'>{tCommon('texts.topTitle')}</div>
                    <SvgIcon component={Logo} viewBox='0 0 151 45' style={{ width: 300, height: 45 }} />
                </div>
            </div>

            <Footer />

            <style jsx>{`
                .custom-div {
                    margin-top: 35vh;
                    transform: translateY(-35%);
                    text-align: center;
                }

                .container {
                    display: grid;
                    grid-template-rows: auto auto;
                    row-gap: 50px;
                    justify-content: center;
                }

                .title {
                    font-weight: 900;
                    font-style: italic;
                    font-size: 18px;
                    text-transform: uppercase;
                }
            `}</style>
        </Fragment>
    );
};

export default Index;

export const getServerSideProps = async (context: GetServerSidePropsContext) => {
    const { redirection_key } = context.query;

    const mode = 'mode' in context.query ? context.query.mode : null;
    const object = { ...context.query };
    delete object.mode;

    // @ts-ignore
    let token: any = await Security.auth(context, redirection_key);

    if ((typeof token === 'object' && 'status' in token && 'statusText' in token) || typeof token !== 'string') {
        token = null;
    }

    return {
        props: {
            mode,
            token,
            messages: {
                ...require(`../messages/common/${getLocale(context)}.json`),
            },
        },
    };
};
